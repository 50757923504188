.launch_box {
    position: absolute;
    width: 2686.93px;
    height: 1556.25px;
    z-index: -10;
    /* left: 2380.3px;
    top: 2742.74px; */
    top: 2700px;
    left: -830px;
    background: rgba(247, 125, 39, 0.11);
    border-radius: 176px;
    transform: rotate(150.87deg);
}


.launch_box_container {
    width: 85%;
    margin-top: 270px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.launch_box_cont {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.launch_box_container .launch_header {
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 48px;
    color: #333333;
}

.launch_box_container .launch_text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    width: 85%;
    line-height: 29px;
    padding-top: 30px;
    color: #616161;
    padding-bottom: 40px;
}

.text__0 {
    width: 100% !important;
}

.launch_image {
    width: 498px;
    height: 408px;
}

.launch_box_container_1 {
    width: 50%;
}

.launch_box_container_2 {
    width: 50%;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.launch_box_container_2_box {
    display: flex;
    /* justify-content: flex-end; */
    /* align-items: flex-end; */
    flex-direction: column;
    width: 498px;
}

.launch_box_section {
    width: 100%;
    height: 200vh;
    margin-top: 70px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
}

.launch_sect {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.box__2 {
    margin-top: 100px !important;
}

@media only screen and (max-width: 600px) {
    .launch_box {
        display: none;
    }

    .launch_box_container {
        flex-direction: column;
        width: 90%;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin-top: 70px;
    }

    .launch_box_container_2 {
        order: 1;
        width: 100%;
    }

    .launch_box_container_1 {
        order: 2;
        width: 100%;
        text-align: left;
    }

    .launch_image {
        width: 100%;
        height: 316px;
    }

    .launch_box_container .launch_header {
        font-size: 20px;
        line-height: 30px;
        padding-top: 20px;

    }

    .launch_box_container .launch_text {

        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        width: 100%;
    }

    .btn___1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .launch_box_container .type__2 {
        order: 2 !important;
    }

    .launch_box_container .type__1 {
        order: 2 !important;
    }

    .launch_box_container_2_box {
        width: 100%;
    }

    .box__2 {
        margin-top: 0px !important;
    }

}

@media only screen and (max-width: 768px) {
    .launch_box {
        display: none;
    }

    .launch_box_container {
        flex-direction: column;
        width: 90%;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin-top: 70px;
    }

    .launch_box_container_2 {
        order: 1;
        width: 100%;
    }

    .launch_box_container_1 {
        order: 2;
        width: 100%;
        text-align: left;
    }

    .launch_image {
        width: 100%;
        height: auto;
    }

    .launch_box_container .launch_header {
        font-size: 20px;
        line-height: 30px;
        padding-top: 20px;

    }

    .launch_box_container .launch_text {

        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        width: 100%;
    }

    .btn___1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .launch_box_container .type__2 {
        order: 2 !important;
    }

    .launch_box_container .type__1 {
        order: 2 !important;
    }

    .launch_box_container_2_box {
        width: 100%;
    }

    .box__2 {
        margin-top: 0px !important;
    }
}