.brand_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    margin-top: 100px;
    background-image: url();
}

.partners {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #BDBDBD;


}

.trusted {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #333333;
}

.marque_cont {
    width: 90%;
}

.img__12 {
    height: 50px;
    width: auto;
    margin-left: 80px;
}

@media only screen and (max-width: 600px) {
    .trusted {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #333333;
    }
}